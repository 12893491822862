import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography'




class ThanksPage extends React.Component{
    render(){
  
      
      return <>
        <Typography variant="h2" gutterBottom>Thanks for voting!</Typography>
        
        </>
    }
  }


export default ThanksPage;